<template>
  <a-modal :width="600" :maskClosable="false" :title="formData.id ? '编辑' : '新增'" :visible="visible"
    @cancel="handleCancel" @ok="handleSubmit" :confirm-loading="confirmLoading" okText="提交审核">
    <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col>
          <a-form-model-item ref="certificateType" label="证件种类" prop="certificateType" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select placeholder="请选择证件种类" v-model="formData.certificateType" option-label-prop="label">
              <a-select-option v-for="item in manuList" :key="item.id" :value="item.id" :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col>
          <a-form-model-item required label="品牌名称" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-select show-search :value="searchVal" placeholder="搜索品牌名称" :default-active-first-option="false"
              :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchUser"
              @change="handleSearchChange">
              <a-select-option v-for="d in brandList" :key="d.name" :label="d.name" :value="d.id">
                {{ d.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item required label="厂商名称" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-select placeholder="请选择厂商名称" :defalut-value="qualificationFactoryName" v-model="qualificationFactoryId" option-label-prop="label">
              <a-select-option v-for="item in shopList" :key="item.id" :value="item.id" :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item required label="资质文件" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="false" v-if="!formData.fileUrl"
              :action="qiNiuAction" :data="postData" @change="handleChange">
              <a-button>
                <a-icon type="upload" /> 上传文件
              </a-button>
            </a-upload>
            <div v-else>
              <a :href="formData.fileUrl" style="margin-left: 5px;" target="_blank">预览文件</a>
              <a @click="removeFile" style="margin-left: 5px;color:red;">删除</a>
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item required label="资质文件" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-upload v-if="!formData.fileUrl" name="file" :accept="'.pdf,.png,.jpg,.jpeg'" :multiple="true" :action="qiNiuAction" :data="postData" @change="handleChange">
              <a-button v-if="!formData.fileUrl"> <a-icon type="upload" /> 上传资质文件 </a-button>
              <a v-if="formData.id && formData.fileUrl" :href="formData.fileUrl" >预览文件</a>
              <a v-if="formData.fileUrl" @click="removeFile" style="margin-left: 5px;color:red;"
                :href="formData.fileUrl" target="_blank">删除</a>
            </a-upload>
            <a v-if="formData.id && formData.fileUrl" :href="formData.fileUrl" target="_blank">预览文件</a>
            <a-button type="link" @click="removeFile" style="margin-left: 10px;color:red;" v-if="formData.id && formData.fileUrl">删除</a-button>
          </a-form-model-item> -->
        </a-col>
      </a-row>
      <!-- <a-row>
        <a-form-model-item label="有效日期" required :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-range-picker style="width: 100%;" :value="dateValue" :ranges="{
    '今天': [moment(), moment()], '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }" @change="dateChange" />
        </a-form-model-item>
      </a-row> -->
      <a-row>
        <a-form-model-item label="有效日期开始" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-date-picker v-model="dateStartTime" @change="onStartChange" />
        </a-form-model-item>
      </a-row>
      <a-row>
        <a-form-model-item label="有效日期结束" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-date-picker style="margin-right: 10px;" v-if="!langTime" v-model="dateEndTime" @change="onEndChange" />
          <a-checkbox v-model="langTime" @change="onLangTime">长期有效</a-checkbox>
        </a-form-model-item>
      </a-row>


    </a-form-model>
  </a-modal>
</template>

<script>
import debounce from 'lodash/debounce';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { selectCitymixins } from '@/mixin/selectCity'
import { publicMixin } from '@/mixin/public';
export default {
  mixins: [selectCitymixins, publicMixin],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    confirmLoading: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    pageType: {
      type: Number,
      default: 1
    }
  },
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      moment,
      rules: {
        certificateType: [{ required: true, message: '请选择证件种类', trigger: 'change' }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      fileList: [],
      qiNiuAction: 'https://upload-z1.qiniup.com',
      qiNiuUploadData: {
        key: '',
        token: ''
      },
      getQiniuTokenIng: false,
      postData: {
        token: ''
      },
      manuList: [{ id: 201, name: '品牌完整授权链' }, { id: 202, name: '商标注册证' }],
      queryData: {
        page: 0,
        size: 10
      },
      brandList: [],
      searchVal: [],
      dateStartTime: "",
      dateEndTime: "",
      langTime:false,//长期有效
      qualificationFactoryId:[],
      qualificationFactoryName:"",
      shopList:[]
    }
  },
  mounted() {
    this.qualificationFactoryId = ""
    if (this.formData.id) {
        let startDate = moment(this.formData.startTime)
        let endDate = moment(this.formData.endTime)
        this.dateValue = [startDate, endDate]
        this.dateStartTime = startDate
        this.dateEndTime = endDate
        this.searchVal = this.formData.name
        this.fetchUser(this.formData.nameDesc)
        if (this.formData.endTime == '4070880000000') {
          this.langTime = true;
        }
        this.qualificationFactoryName = this.formData.qualificationFactoryName
        this.qualificationFactoryId = this.formData.qualificationFactoryId
      }
    this.getQiniuToken()
    this.getBrandList();
    this.getShopList();
  },
  methods: {
    getShopList(){
      request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/list', 'post', {page:0,size:300,type:300,auditStatus:2,certificateStatus:1}).then(res => {
        if (res.data.code == 200) {
          this.shopList = res.data.data.data;
          const hasIdOne = this.shopList.some(item => item.id == this.qualificationFactoryId);
          if(!hasIdOne) {
            this.qualificationFactoryId = []
          }

        }
      })
    },
    onStartChange(date, dateString) {
      this.dateStartTime = date;
      this.formData.startTime = dateString + ' 00:00:00'
    },
    onEndChange(date, dateString) {
      this.dateEndTime = date;
      this.formData.endTime = dateString + ' 23:59:59'
    },
    onLangTime(e){
      console.log(`checked = ${e.target.checked}`);
        this.langTime = e.target.checked
    },
    handleSearchChange(searchVal) {
      console.log(searchVal,'searchVal')
      this.formData.name = searchVal;
      Object.assign(this, {
        searchVal,
        fetching: false,
      });
    },
    fetchUser(value) {
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.brandList = [];
      this.fetching = true;
      console.log(value, 'value')
      this.queryData.name = value;
      // 使用 request 函数从指定接口获取数据
      request(process.env.VUE_APP_API_BASE_URL + 'srm/brand/selectList', 'post', this.queryData).then(res => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return;
        }
        console.log(res, 'res---')
        this.brandList = res.data.data.data;
        this.fetching = false;
      })
        .catch(error => {
          console.error('Error fetching user:', error);
          // 处理错误
        });
    },
    getBrandList() {
      request(process.env.VUE_APP_API_BASE_URL + 'srm/brand/selectList', 'post', this.queryData).then(res => {
        if (res.data.code == 200) {
          this.brandList = res.data.data.data;
          this.queryData.page = res.data.data.page
          this.queryData.size = res.data.data.size
        }
      })
    },
    dateChange(date, dateString) {
      this.dateValue = date;
      this.formData.startTime = dateString[0] + ' 00:00:00'
      this.formData.endTime = dateString[1] + ' 23:59:59'
      console.log(this.formData, 'this.formData')
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('上传文件类型有误!');
      }
      const isLt30M = file.size / 1024 / 1024 < 30;
      if (!isLt30M) {
        this.$message.error('Image must be smaller than 30MB!');
      }
      return isJpgOrPng && isLt30M;
    },
    handleChange(info) {
      const { fileList } = info;
      if (fileList.length > 0 && fileList[0].status === 'done') {
        console.log('上传成功:', fileList[0].response);
        this.$set(this.formData, 'fileUrl', fileList[0].response.url)
      }
      // if (info.file.status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      //   this.formData.fileUrl = info.file.response.url
      //   this.$set(this.formData, 'fileUrl', info.file.response.url)
      // }
      // if (info.file.status === 'done') {
      //   this.$message.success(`${info.file.name} 上传成功`);
      // } else if (info.file.status === 'error') {
      //   this.$message.error(`${info.file.name} 上传失败，请刷新页面重试`);
      // }
    },
    getQiniuToken() {
      if (!this.getQiniuTokenIng) {
        this.getQiniuTokenIng = true
        this.loading = true
        this.loadingText = 'token加载中'
        request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb', 'get').then((res) => {
          if (res.data.code == 200) {
            this.postData.token = res.data.data
            // this.postData.key = 'sfa/upload' + Math.floor(Math.random() * 100) + new Date().valueOf()
            this.getQiniuTokenIng = false
          }
        })
      }
    },
    onChange(value) {
      console.log(value);
    },
    removeFile() {
      this.formData.fileUrl = ""
      this.fileList = []
    },
    handleCancel() {
      // 处理取消操作
      this.$emit('cancel');
    },
    handleSubmit() {
      // 处理表单提交
      console.log(this.formData,'!this.formData')
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!this.formData.certificateType) {
            this.$message.error('请选择证件种类');
            return false;
          }
           // 长期有效
           if(this.langTime) {
            this.formData.endTime = "2099-1-1 00:00:00"
          }
          if (!this.formData.startTime || !this.formData.endTime) {
            this.$message.error('请选择有效期');
            return false;
          }
          // if (this.pageType == 3 && !this.formData.name) {
          //   this.$message.error('请输入厂商名称！');
          //   return false;
          // }
          if(!this.formData.fileUrl) {
            this.$message.error('请上传资质文件！');
            return false;
          }
          if(!this.qualificationFactoryId || this.qualificationFactoryId.length == 0) {
            this.$message.error('请选择厂商！');
            return false;
          }
          this.confirmLoading = true;

          let obj = {
              name:this.formData.name,
              qualificationFactoryId:this.qualificationFactoryId,
              "qualificationFileList": [this.formData]
            }
            
          this.$emit('submit', obj)
          this.confirmLoading = true;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>
<style>
.title-text {
  font-weight: 700;
  font-size: 16px;
  color: #333;
}
</style>
